import React, { Fragment, useState } from 'react';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, FormText } from 'reactstrap'; // Importing Modal components
import { Confirmation } from '../../../../../../Base/Modal';
import { CreateButton, CancelButton } from '../../../../../../Base/Buttons';
import { AccountFormSelect } from '../../../../../../Base/Account';

const exitConditionsOptions = [
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Hired', value: 'HIRED' },
];

function SettingsModal({ isOpen, toggle, onSave, initialValues }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [tempValues, setTempValues] = useState(initialValues);

  const handleChange = (name, value) => {
    setTempValues({
      ...tempValues,
      [name]: value,
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    onSave(tempValues);
    setValues(tempValues); // Update the saved values
    toggle();
  };

  const handleCancel = () => {
    setTempValues(values);
    toggle();
  };

  const handleClose = () => {
    if (JSON.stringify(tempValues) !== JSON.stringify(values)) {
      setShowConfirm(true);
    } else {
      toggle();
    }
  };

  const confirmClose = () => {
    setShowConfirm(false);
    handleCancel();
  };

  return (
    <Fragment>
      <Modal centered isOpen={isOpen} toggle={handleClose} backdrop="static">
        <ModalHeader toggle={handleClose}>Settings</ModalHeader>
        <ModalBody
          style={{
            overflowY: 'visible',
          }}
        >
          <FormGroup>
            <Label>Name</Label>
            <Input id="name" onChange={(e) => setName(e.target.value)} type="text" value={values.name || ''} />
          </FormGroup>
          <AccountFormSelect
            className="account-opts"
            classNamePrefix="react-select"
            currentAccounts={(values.sharedWith || []).map(({ accountId, accountName, readOnly }) => ({
              id: accountId,
              name: accountName,
              readOnly: readOnly,
            }))}
            id="sharedWith"
            isMulti
            label="Shared With"
            onChange={(accountObjs = []) => {
              const accounts = accountObjs.map(({ value, label, readOnly }) => ({
                accountId: value,
                accountName: label,
                readOnly,
              }));
              handleChange('sharedWith', accounts);
            }}
          />
          <FormGroup>
            <Label>Exit Conditions</Label>
            <Select
              classNamePrefix="react-select"
              isMulti
              onChange={(val) => {
                handleChange(
                  'exitConditions',
                  val.map(({ value }) => value),
                );
              }}
              options={exitConditionsOptions}
              placeholder="Choose Category"
              value={tempValues.exitConditions?.map((value) =>
                exitConditionsOptions.find((option) => option.value === value),
              )}
            />
            <FormText>
              If a candidate is in the workflow and meets the exit conditions, they will be removed from the workflow.
            </FormText>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CancelButton action={handleClose} />
          <CreateButton label="Save" action={handleSave} />
        </ModalFooter>
      </Modal>
      <Confirmation
        content="Your data will be lost if you close the modal"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={confirmClose}
      />
    </Fragment>
  );
}

export default SettingsModal;
