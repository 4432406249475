import React, { useState } from 'react';
import styled from 'styled-components';

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #f0e0e0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: ${(props) => (props.isDisabled ? '#f0f0f0' : props.isActive ? '#F6FEF9' : '#fdedf4')};
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
`;

const ToggleButton = styled.button`
  width: 28px;
  height: 18px;
  background-color: ${(props) => (props.isDisabled ? '#ccc' : props.isActive ? '#17B96E' : '#DE5471')};
  border-radius: 12px;
  border: none;
  position: relative;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  padding: 0;
  transition: background-color 0.3s ease;

  &:focus {
    outline: none;
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  top: 2px;
  left: ${(props) => (props.isActive ? '13px' : '1px')};
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const StatusLabel = styled.span`
  font-weight: bold;
  color: ${(props) => (props.isDisabled ? '#999' : props.isActive ? '#1F3D7B' : '#1A2E56')};
  transition: all 0.3s ease;
  font-size: 0.7rem;
  line-height: 0.7rem;
`;

const ActiveDraftToggle = ({ onChange, initialStatus, isDisabled }) => {
  const [isActive, setIsActive] = useState(initialStatus === 'LIVE');

  const toggleStatus = () => {
    if (!isDisabled) {
      const newStatus = isActive ? 'DRAFT' : 'LIVE';
      setIsActive(!isActive);
      if (onChange) {
        onChange(newStatus);
      }
    }
  };

  return (
    <ToggleWrapper isActive={isActive} isDisabled={isDisabled}>
      <ToggleButton
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={toggleStatus}
        disabled={isDisabled}
        aria-pressed={isActive}
      >
        <ToggleSlider isActive={isActive} />
      </ToggleButton>
      <StatusLabel isActive={isActive} isDisabled={isDisabled}>
        {isActive ? 'Enabled' : 'Disabled'}
      </StatusLabel>
    </ToggleWrapper>
  );
};

export default ActiveDraftToggle;
