import React from 'react';
import FunnelSelectInputs from '../../../../../../../Base/Forms/Custom/FunnelSelect/FunnelSelectInputs';
import EmailTemplateSelectInput from './EmailTemplateSelectInput';
import SendEmailInput from './SendEmailInput';
import SendIntegrationInput from './SendIntegrationInput';

function ActionNode({ option, setOption }) {
  if (
    option?.actionType === 'REQUEST_ONBOARDING' ||
    option?.actionType === 'REQUEST_RTW' ||
    option?.actionType === 'REQUEST_EDOC' ||
    option?.actionType === 'REQUEST_GENERIC_FORM' ||
    option?.actionType === 'REQUEST_DBS' ||
    option?.actionType === 'REQUEST_REFEREES' ||
    option?.actionType === 'REQUEST_REFERENCES' ||
    option?.actionType === 'REQUEST_PERSONALITY_TEST'
  ) {
    return (
      <>
        <EmailTemplateSelectInput
          actionType={option?.actionType}
          id={`emailTemplateConfig-`}
          onChange={(templateId) => setOption({ templateId })}
          required
          value={option?.templateId || ''}
        />
      </>
    );
  }

  if (option?.actionType === 'SEND_EMAIL') {
    return <SendEmailInput option={option} setOption={setOption} />;
  }

  if (option?.actionType === 'SEND_TO_INTEGRATION') {
    return <SendIntegrationInput option={option} setOption={setOption} />;
  }

  if (option?.actionType === 'UPDATE_FUNNEL_STAGE') {
    return (
      <FunnelSelectInputs
        funnelSelectProps={{
          id: `actionFunnelId`,
          label: 'Funnel',
          required: true,
          value: option?.funnelId || '',
        }}
        onChange={(val) => {
          setOption({ funnelId: val.funnelId, funnelStageId: val.funnelStageId });
        }}
        stageSelectProps={{
          id: `funnelStageId`,
          label: 'Stage',
          required: true,
          value: option?.funnelStageId || '',
        }}
      />
    );
  }
}

export default ActionNode;
