import { inputNodes, actionNodes, waitNodes, decisionNodes, notesNodes, exitNodes } from './NodeTypesLists';

const allNodes = [...actionNodes, ...waitNodes, ...decisionNodes, ...notesNodes, ...exitNodes, ...inputNodes];

export function getNextId(nodes) {
  if (nodes.length === 0) return '0';
  return String(Math.max(...nodes.map((node) => parseInt(node.id, 10))) + 1);
}

export function findClosestNode(position, nodes) {
  let closestNode = null;
  let closestDistance = Infinity; // Initialize with a large number

  nodes.forEach((node) => {
    const nodeBounds = {
      x: node.position.x,
      y: node.position.y,
      width: node.measured?.width,
      height: node.measured?.height,
    };

    // Calculate the center of the node
    const nodeCenter = {
      x: nodeBounds.x + nodeBounds.width / 2,
      y: nodeBounds.y + nodeBounds.height / 2,
    };

    // Calculate the Euclidean distance from the position to the node center
    const distance = Math.sqrt(Math.pow(position.x - nodeCenter.x, 2) + Math.pow(position.y - nodeCenter.y, 2));

    // Check if position is within the expanded bounds (100px from each side)
    const isWithinBounds =
      position.x >= nodeBounds.x - 100 &&
      position.x <= nodeBounds.x + nodeBounds.width + 100 &&
      position.y >= nodeBounds.y - 100 &&
      position.y <= nodeBounds.y + nodeBounds.height + 100;

    // If within bounds and the distance is less than the closestDistance, update closestNode
    if (isWithinBounds && distance < closestDistance) {
      closestNode = node;
      closestDistance = distance; // Update the closest distance
    }
  });

  return closestNode;
}

export function validateWorkflow(nodes, edges) {
  const errors = [];

  // Helper functions to find edges related to a node
  const findIncomingEdges = (nodeId) => edges.filter((edge) => edge.target === nodeId);
  const findOutgoingEdges = (nodeId) => edges.filter((edge) => edge.source === nodeId);

  let startNodeCount = 0;
  let endNodeCount = 0;
  const nodeIds = new Set();

  // Define required fields for specific actionTypes
  const actionTypeRequirements = {
    EDOC_STATUS_CHANGE: ['condition'],
    SEND_EMAIL: ['templateId'],
    WAIT: ['number', 'range'],
  };

  nodes.forEach((node) => {
    const foundNode = allNodes.find((inputNode) => inputNode.actionType === node.data.actionType);

    if (nodeIds.has(node.id)) {
      errors.push({ id: node.id, message: `Duplicate node ID found: ${node.id}` });
    } else {
      nodeIds.add(node.id);
    }

    const incomingEdges = findIncomingEdges(node.id);
    const outgoingEdges = findOutgoingEdges(node.id);

    if (node.type === 'startNode') {
      startNodeCount += 1;
      if (outgoingEdges.length === 0) {
        errors.push({ id: node.id, message: `Start node does not have any outgoing edges.` });
      }
    } else if (node.type === 'endNode') {
      endNodeCount += 1;
      if (incomingEdges.length === 0) {
        errors.push({ id: node.id, message: `End node does not have any incoming edges.` });
      }
    } else {
      if (incomingEdges.length === 0) {
        errors.push({ id: node.id, message: `${foundNode?.label} does not have any incoming edges.` });
      }
      if (outgoingEdges.length === 0) {
        errors.push({ id: node.id, message: `${foundNode?.label} does not have any outgoing edges.` });
      }
    }

    if (incomingEdges.length === 0 && outgoingEdges.length === 0) {
      errors.push({ id: node.id, message: `${foundNode?.label} is disconnected from the workflow.` });
    }

    if (node.type === 'decisionNode') {
      if (outgoingEdges.length !== 2) {
        errors.push({
          id: node.id,
          message: `${foundNode?.label} must have exactly two outgoing edges.`,
        });
      }
    }

    const actionType = node.data?.actionType;
    if (actionType && actionTypeRequirements[actionType]) {
      const requiredFields = actionTypeRequirements[actionType];
      requiredFields.forEach((field) => {
        if (!(field in node.data)) {
          errors.push({ id: node.id, message: `${foundNode?.label} is missing required field '${field}'.` });
        }
      });

      if (actionType === 'WAIT') {
        const numberValue = parseFloat(node.data.number);
        if (isNaN(numberValue) || numberValue <= 0) {
          errors.push({ id: node.id, message: `${foundNode?.label} has an invalid 'number' value.` });
        }
        const validRanges = ['min', 'hour', 'day'];
        if (!validRanges.includes(node.data.range)) {
          errors.push({
            id: node.id,
            message: `${foundNode?.label} has an invalid 'range' value. It must be one of ${validRanges.join(', ')}.`,
          });
        }
      }
    }
  });

  if (startNodeCount !== 1) {
    errors.push({
      id: 'startNodeValidation',
      message: `There must be exactly one start node.`,
    });
  }

  if (endNodeCount < 1) {
    errors.push({ id: 'endNodeValidation', message: `There must be at least one end node.` });
  }

  edges.forEach((edge) => {
    const sourceNode = nodes.find((node) => node.id === edge.source);
    const targetNode = nodes.find((node) => node.id === edge.target);
    if (!sourceNode) {
      errors.push({ id: edge.id, message: `Edge '${edge.id}' has a non-existent source node: ${edge.source}` });
    }
    if (!targetNode) {
      errors.push({ id: edge.id, message: `Edge '${edge.id}' has a non-existent target node: ${edge.target}` });
    }

    if ((edge.type === 'yesEdge' || edge.type === 'noEdge') && sourceNode?.type !== 'decisionNode') {
      errors.push({ id: edge.id, message: `Edge '${edge.id}' connects to an invalid node type for this edge.` });
    }
  });

  return errors;
}

function getStartNodeFilters(nodeInfo) {
  let filters = {};

  switch (nodeInfo?.actionType) {
    case 'SEND_CONTACT_TEMPLATE_EMAIL':
    case 'SEND_EMAIL':
    case 'GENERIC_FORM_STATUS_CHANGE':
    case 'ONBOARDING_STATUS_CHANGE':
    case 'EDOC_STATUS_CHANGE':
    case 'REFEREE_STATUS_CHANGE':
    case 'DBS_STATUS_CHANGE':
    case 'REFERENCE_STATUS_CHANGE':
    case 'PAYROLL_INTEGRATION_SUBMISSION':
    case 'RTW_STATUS_CHANGE':
      filters = [{ field: 'status', operation: 'EQ', value: nodeInfo.condition }];
      break;
    case 'CREATE_CANDIDATE_EVENT':
      filters = [
        { field: 'funnelData.funnelId', operation: 'IN', value: [nodeInfo.funnelId] },
        { field: 'outcome', operation: 'EQ', value: nodeInfo.condition?.value },
        { field: 'status', operation: 'EQ', value: nodeInfo.condition?.value },
      ];
      break;
    case 'EVENT_STATUS_CHANGE':
      filters = [
        { field: 'eventTypeId', operation: 'IN', value: [nodeInfo.eventType] },
        { field: 'status', operation: 'EQ', value: nodeInfo.condition },
      ];
      break;
    case 'FUNNEL_STATE_CHANGE':
      filters = [
        { field: 'funnelData.funnelId', operation: 'IN', value: [nodeInfo.funnelId] },
        { field: 'funnelData.stageId', operation: 'IN', value: [nodeInfo.stageId] },
      ];
      break;
    case 'PERSONALITY_TEST_STATUS_CHANGE':
      filters = [{ field: 'status', operation: 'EQ', value: nodeInfo.condition }];

      if (nodeInfo.condition === 'RECEIVED') {
        filters.push({ field: 'operator', operation: 'EQ', value: nodeInfo.operator.value });

        if (nodeInfo.operator.value !== 'ALL') {
          filters.push(
            { field: 'score', operation: 'EQ', value: nodeInfo.scoreValue },
            { field: 'scoreType', operation: 'EQ', value: nodeInfo.scoreType.value },
          );
        }
      }
      break;
    default:
      filters = {};
      break;
  }

  return filters;
}

function convertTime(number, range) {
  let timeInMinutes = 0;

  if (range === 'min') {
    timeInMinutes = number * 60;
  } else if (range === 'hour') {
    timeInMinutes = number * 60 * 60;
  } else if (range === 'day') {
    timeInMinutes = number * 60 * 60 * 24;
  }

  return timeInMinutes;
}

export function convertWorkflow(workflow) {
  const { nodes, edges, name, activeAccountId, sharedWith, exitCondtions } = workflow;

  const startNode = nodes.find((node) => node.type === 'startNode');

  const convertedWorkflow = {
    name: name,
    accountId: activeAccountId,
    type: startNode?.data?.actionType,
    sharedWith: sharedWith,
    stages: [],
    dimensions: {
      w: startNode.measured.width,
      h: startNode.measured.height,
    },
    coords: startNode.position,
    edges: edges,
    filters: getStartNodeFilters(startNode.data),
  };

  if (exitCondtions) {
    convertedWorkflow.exitCondtions = [
      {
        condition: { field: 'latestFunnelStage.stageType', operation: 'IN', value: exitCondtions },
        evaluatorType: 'CANDIDATE',
      },
    ];
  }

  const filteredNodes = nodes.filter((node) => node.type !== 'startNode');

  filteredNodes.forEach((node) => {
    const { label, actionType, id, ...rest } = node.data;

    let stage = {
      name: label,
      label: label,
      stageNo: parseInt(node.id),
      type: actionType,
      delay: 0,
      props: {
        ...rest,
      },
      actionOnFail: 'END',
      dimensions: {
        w: node.measured.width,
        h: node.measured.height,
      },
      coords: node.position,
    };

    if (actionType === 'WAIT') {
      stage = {
        ...stage,
        props: {
          ...stage.props,
          delay: convertTime(stage.props.number, stage.props.range),
        },
      };
    }
    convertedWorkflow.stages.push(stage);
  });

  return convertedWorkflow;
}

function reverseGetStartNodeFilters(filters, actionType) {
  let nodeInfo = {};

  switch (actionType) {
    case 'SEND_CONTACT_TEMPLATE_EMAIL':
    case 'SEND_CANDIDATE_CONTACT_EMAIL':
    case 'GENERIC_FORM_STATUS_CHANGE':
    case 'ONBOARDING_STATUS_CHANGE':
    case 'EDOC_STATUS_CHANGE':
    case 'REFEREE_STATUS_CHANGE':
    case 'DBS_STATUS_CHANGE':
    case 'REFERENCE_STATUS_CHANGE':
    case 'PAYROLL_INTEGRATION_SUBMISSION':
    case 'RTW_STATUS_CHANGE': {
      let status = '';

      const statusEQFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'EQ');
      const statusINFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'IN');

      if (statusEQFilter) {
        status = statusEQFilter.value;
      } else if (statusINFilter) {
        status = statusINFilter.value[0];
      }

      nodeInfo.condition = statusINFilter || statusEQFilter ? status : '';
      break;
    }

    case 'CREATE_CANDIDATE_EVENT': {
      const funnelIdFilter = filters.find(
        (filter) => filter.field === 'funnelData.funnelId' && filter.operation === 'IN',
      );

      const funnelUTMCampaignFilter = filters.find(
        (filter) => filter.field === 'utmCampaign' && filter.operation === 'IN',
      );

      const funnelUTMSourceFilter = filters.find((filter) => filter.field === 'utmSource' && filter.operation === 'IN');

      const funnelUTMMediumFilter = filters.find((filter) => filter.field === 'utmMedium' && filter.operation === 'IN');

      if (funnelIdFilter) {
        const outcomeFilter = filters.find((filter) => filter.field === 'outcome' && filter.operation === 'EQ');
        const statusFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'EQ');

        nodeInfo.funnelId = funnelIdFilter && funnelIdFilter.value[0] ? funnelIdFilter.value[0] : '';
        nodeInfo.condition = {
          value: outcomeFilter ? outcomeFilter.value : statusFilter ? statusFilter.value : '',
        };
      } else if (funnelUTMCampaignFilter) {
        nodeInfo.scoreValue = funnelUTMCampaignFilter.value[0];
        nodeInfo.condition = {
          value: 'UTMMEDIUM',
        };
      } else if (funnelUTMSourceFilter) {
        nodeInfo.scoreValue = funnelUTMSourceFilter.value[0];
        nodeInfo.condition = {
          value: 'UTMSOURCE',
        };
      } else if (funnelUTMMediumFilter) {
        nodeInfo.scoreValue = funnelUTMMediumFilter.value[0];
        nodeInfo.condition = {
          value: 'UTMMEDIUM',
        };
      }

      break;
    }

    case 'EVENT_STATUS_CHANGE': {
      const eventTypeFilter = filters.find((filter) => filter.field === 'eventTypeId' && filter.operation === 'IN');
      const statusFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'EQ');

      nodeInfo.eventType = eventTypeFilter && eventTypeFilter.value[0] ? eventTypeFilter.value[0] : '';
      nodeInfo.condition = statusFilter ? statusFilter.value : '';
      break;
    }

    case 'FUNNEL_STATE_CHANGE': {
      const funnelIdFilter = filters.find(
        (filter) => filter.field === 'funnelData.funnelId' && filter.operation === 'IN',
      );
      const stageIdFilter = filters.find(
        (filter) => filter.field === 'funnelData.stageId' && filter.operation === 'IN',
      );

      nodeInfo.funnelId = funnelIdFilter && funnelIdFilter.value[0] ? funnelIdFilter.value[0] : '';
      nodeInfo.stageId = stageIdFilter && stageIdFilter.value[0] ? stageIdFilter.value[0] : '';
      break;
    }

    case 'PERSONALITY_TEST_STATUS_CHANGE': {
      const statusFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'EQ');
      nodeInfo.condition = statusFilter ? statusFilter.value : '';

      if (nodeInfo.condition === 'RECEIVED') {
        const operatorFilter = filters.find((filter) => filter.field === 'operator' && filter.operation === 'EQ');
        nodeInfo.operator = { value: operatorFilter ? operatorFilter.value : '' };

        if (nodeInfo.operator.value !== 'ALL') {
          const scoreFilter = filters.find((filter) => filter.field === 'score' && filter.operation === 'EQ');
          const scoreTypeFilter = filters.find((filter) => filter.field === 'scoreType' && filter.operation === 'EQ');

          nodeInfo.scoreValue = scoreFilter ? scoreFilter.value : '';
          nodeInfo.scoreType = { value: scoreTypeFilter ? scoreTypeFilter.value : '' };
        }
      }
      break;
    }

    default:
      // Handle other cases if necessary
      break;
  }

  return nodeInfo;
}

export function reverseConvertWorkflow(convertedWorkflow) {
  const { name, accountId, type, sharedWith, stages, dimensions, coords, edges, filters, id, exitCondtions } =
    convertedWorkflow;

  const nodes = [];

  // Reconstruct the start node
  const startNodeData = {
    actionType: type,
    ...reverseGetStartNodeFilters(filters, type),
  };

  const startNode = {
    type: 'startNode',
    data: startNodeData,
    measured: {
      width: dimensions?.w || 50,
      height: dimensions?.h || 50,
    },
    position: coords,
    id: '0',
    selected: false,
  };

  nodes.push(startNode);

  // Reconstruct other nodes
  stages.forEach((stage) => {
    const { label, type: actionType, props, dimensions: stageDimensions, coords: stageCoords, stageNo } = stage;

    const nodeData = {
      label,
      actionType,
      ...props,
    };
    let nodeType = 'actionNode';

    if (actionType === 'WAIT') {
      nodeData.number = props.number;
      nodeData.range = props.range;
      nodeType = 'waitNode';
    }

    if (actionType === 'EXIT') {
      nodeType = 'endNode';
    }

    const node = {
      data: nodeData,
      type: nodeType,
      id: stageNo.toString(),
      measured: {
        width: stageDimensions?.w || 50,
        height: stageDimensions?.h || 50,
      },
      position: stageCoords,
      selected: false,
    };

    nodes.push(node);
  });

  let funnelStageExitConditions = [];

  if (exitCondtions) {
    exitCondtions?.forEach((condition) => {
      if (condition?.condition?.field === 'latestFunnelStage.stageType') {
        if (condition?.condition?.operation === 'IN') {
          funnelStageExitConditions = condition?.condition?.value;
        } else if (condition?.condition?.operation === 'EQ') {
          funnelStageExitConditions = [condition?.condition?.value];
        }
      }
    });
  }

  const workflow = {
    nodes,
    edges,
    name,
    activeAccountId: accountId,
    sharedWith,
    id,
    exitCondtions: funnelStageExitConditions,
  };

  return workflow;
}
